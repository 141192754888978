<div *ngIf="!submitted">
  <form (ngSubmit)="onSubmit()" #form="ngForm">
    <div *ngIf="component.data.fields.length > 0">
      <div class="form-container">
        <div
          class="form-group mb-3 has-validation"
          *ngFor="let field of component.data.fields"
          [ngClass]="{ 'form-switch': field.type == 'checkbox' }"
        >
          <label for="{{ field.id }}" class="form-label mb-2"
            >{{ field.label }}
            <i
              *ngIf="field.instructions"
              class="bx bx-info-circle pointer"
              style="display: inline-block"
              [tooltip]="field.instructions"
              containerClass="scenario-ui-{{ scenario?.id }} ys-tooltip"
              [adaptivePosition]="true"
              container="body"
              boundariesElement="viewport"
              ><span class="visually-hidden">Instructions</span></i
            ></label
          >
          <ng-container [ngSwitch]="field.type">
            <div *ngSwitchCase="'text'">
              <input
                type="text"
                name="{{ field.id }}"
                id="{{ field.id }}"
                [(ngModel)]="formData[field.id]"
                class="form-control"
                [required]="field.required"
                #fieldInput="ngModel"
                [ngClass]="{ 'is-invalid': !fieldInput.valid && formSubmitted }"
                [attr.placeholder]="
                  field.placeholder ? field.placeholder : null
                "
              />
            </div>
            <div *ngSwitchCase="'number'">
              <input
                type="number"
                name="{{ field.id }}"
                id="{{ field.id }}"
                [(ngModel)]="formData[field.id]"
                class="form-control"
                [required]="field.required"
                #fieldInput="ngModel"
                [ngClass]="{ 'is-invalid': !fieldInput.valid && formSubmitted }"
                [attr.placeholder]="
                  field.placeholder ? field.placeholder : null
                "
              />
            </div>
            <div *ngSwitchCase="'textarea'">
              <textarea
                name="{{ field.id }}"
                id="{{ field.id }}"
                class="form-control"
                [(ngModel)]="formData[field.id]"
                [required]="field.required"
                #fieldInput="ngModel"
                [ngClass]="{ 'is-invalid': !fieldInput.valid && formSubmitted }"
                [attr.placeholder]="
                  field.placeholder ? field.placeholder : null
                "
              ></textarea>
            </div>
            <div *ngSwitchCase="'checkbox'">
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                name="{{ field.id }}"
                id="{{ field.id }}"
                [(ngModel)]="formData[field.id]"
                [required]="field.required"
                #fieldInput="ngModel"
                [ngClass]="{ 'is-invalid': !fieldInput.valid && formSubmitted }"
              />
            </div>
            <div *ngSwitchCase="'select'">
              <select
                name="{{ field.id }}"
                id="{{ field.id }}"
                class="form-select"
                [(ngModel)]="formData[field.id]"
                [required]="field.required"
                #fieldInput="ngModel"
                [ngClass]="{ 'is-invalid': !fieldInput.valid && formSubmitted }"
              >
                <option
                  *ngFor="let option of field.config.options"
                  value="{{ option }}"
                >
                  {{ option }}
                </option>
              </select>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="form-group mb-3" *ngIf="component.data.fields.length > 0">
        <button class="btn btn-ys">
          <ng-container *ngIf="component.data.submitLabel">{{
            component.data.submitLabel
          }}</ng-container>
          <ng-container *ngIf="!component.data.submitLabel"
            >Submit</ng-container
          >
        </button>
      </div>
    </div>
  </form>
</div>
<div *ngIf="submitted">
  <div *ngIf="component.data.feedback" class="form-feedback">
    {{ component.data.feedback }}
  </div>
  <div *ngIf="!component.data.feedback" class="form-feedback">
    Task completed
  </div>
  <button
    class="btn btn-choice btn-ys"
    (click)="onComplete()"
    *ngIf="!completed && !component.data.disableContinue"
  >
    <ng-container *ngIf="component.data.continueLabel">{{
      component.data.continueLabel
    }}</ng-container>
    <ng-container *ngIf="!component.data.continueLabel">Continue</ng-container>
  </button>
</div>
<div class="task-obscure" *ngIf="completed && component.data.obscure"></div>
